import { Item } from './elements/item';
import classNames from 'classnames';
import { RichText } from '@/components/rich-text';

export const Accordion = ({ items, numbered }) => {
    return (
        <div
            className={classNames('accordion', {
                'accordion accordion--numbered': numbered,
            })}
        >
            {items.map((item, index) => {
                const count = index + 1;
                return (
                    <Item
                        key={index}
                        item={item}
                        count={count}
                        numbered={numbered}
                    >
                        <RichText
                            className="accordion__content"
                            data={item.description}
                        />
                    </Item>
                );
            })}
        </div>
    );
};
