'use client';

import { Form1a } from '@/blocks/form/variants/form-1a'; // sidebar title top
import { Form1b } from '@/blocks/form/variants/form-1b'; // sidebar title right

import { Form2a } from '@/blocks/form/variants/form-2a'; // no sidebar
import { Form2b } from '@/blocks/form/variants/form-2b'; // no sidebar, short introduction
import { Form2c } from '@/blocks/form/variants/form-2c';

import { Form3a } from '@/blocks/form/variants/form-3a';

import { Form4a } from '@/blocks/form/variants/form-4a';

import { Form5a } from '@/blocks/form/variants/form-5a';

import { FormMarketoIframe } from '@/blocks/form/variants/form-marketo-iframe';
import { Suspense } from 'react';

const prefix = 'form';

const variants = {
    [`${prefix}_1a`]: Form1a,
    [`${prefix}_1b`]: Form1b,
    [`${prefix}_2a`]: Form2a,
    [`${prefix}_2b`]: Form2b,
    [`${prefix}_2c`]: Form2c,
    [`${prefix}_3a`]: Form3a,
    [`${prefix}_4a`]: Form4a,
    [`${prefix}_5a`]: Form5a,
    [`${prefix}_marketo_iframe`]: FormMarketoIframe,
};

export const Form = ({ ...block }) => {
    const Component = variants[block.variant];
    return Component ? (
        <Suspense>
            <Component {...block} />
        </Suspense>
    ) : null;
};
