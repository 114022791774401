import { Media } from '@/components/media';
import { Footnotes } from '@/components/footnotes';
import { Feature } from '../elements/feature';
import classNames from 'classnames';
import { IntroButton } from '@/components/intro-button';
import { getSizes } from '@/lib/utils/get-sizes';

export const Features2c = ({
    settings,
    center_items,
    elevation,
    offset_media,
    introduction,
    button,
    media,
    features,
    footnotes,
    attr,
}) => {
    return (
        <section
            className={classNames('section features', {
                ...settings,
            })}
            {...attr}
        >
            <div className="container">
                <IntroButton introduction={introduction} button={button} />
                <div
                    className={classNames(
                        'grid-md-12 row-gap-14 row-gap-md-7 features__items',
                        {
                            'mt-6': offset_media,
                        }
                    )}
                >
                    <div className="col-md-5">
                        {media && (
                            <Media
                                media={media}
                                sizes={getSizes({
                                    desktop: 23,
                                    tablet: 39,
                                })}
                            />
                        )}
                    </div>
                    <div
                        className={classNames(
                            'col-start-md-7 col-end-md-13 grid-md-2',
                            {
                                'row-gap-md-12': !offset_media,
                                'mt-8 mt-md-0 row-gap-20 row-gap-md-22':
                                    offset_media,
                            }
                        )}
                    >
                        {features.map((feature, index) => {
                            return (
                                <Feature
                                    key={index}
                                    center_items={center_items}
                                    elevation={elevation}
                                    offset_media={offset_media}
                                    variant={1}
                                    {...feature}
                                />
                            );
                        })}
                    </div>
                </div>
                {footnotes?.value && <Footnotes footnotes={footnotes} />}
            </div>
        </section>
    );
};
