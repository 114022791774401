import { Media } from '@/components/media';
import { Footnotes } from '@/components/footnotes';
import { Feature } from '../elements/feature';
import classNames from 'classnames';
import { IntroButton } from '@/components/intro-button';
import { getSizes } from '@/lib/utils/get-sizes';

export const Features4a = ({
    settings,
    center_items,
    elevation,
    introduction,
    button,
    media,
    features,
    footnotes,
    attr,
}) => {
    return (
        <section
            className={classNames('section features', {
                ...settings,
            })}
            {...attr}
        >
            <div className="container">
                <IntroButton introduction={introduction} button={button} />
                {media && (
                    <div className={'features__media'}>
                        <Media
                            media={media}
                            sizes={getSizes({
                                desktop: 58,
                                tablet: 97,
                            })}
                        />
                    </div>
                )}

                <div className="grid-md-1 row-gap-md-12 features__items">
                    {features.map((feature, index) => {
                        return (
                            <Feature
                                key={index}
                                center_items={center_items}
                                elevation={elevation}
                                variant={2}
                                {...feature}
                            />
                        );
                    })}
                </div>
                {footnotes?.value && <Footnotes footnotes={footnotes} />}
            </div>
        </section>
    );
};
