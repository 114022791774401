import { Tabs1a } from '@/blocks/tabs/variants/tabs-1a';
import { Tabs1b } from '@/blocks/tabs/variants/tabs-1b';
import { Tabs1c } from '@/blocks/tabs/variants/tabs-1c';

const prefix = 'tabs';

const variants = {
    [`${prefix}_1a`]: Tabs1a,
    [`${prefix}_1b`]: Tabs1b,
    [`${prefix}_1c`]: Tabs1c,
};

export const Tabs = ({ ...block }) => {
    const Component = variants[block.variant];
    return Component ? <Component {...block} /> : null;
};
