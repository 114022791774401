'use client';

import { useModal } from '@/lib/hooks/use-modal';
import { Modal } from '@/components/modal';
import { Button } from '@/components/button';
import { VideoModalContent } from '@/components/media/elements/video-modal-content';

export const ResourceButtons = ({ resource, size = 'md' }) => {
    const { isOpen, setIsOpen } = useModal();
    const style = resource?.media?.button?.btn_style || 'secondary';
    return (
        <>
            {(resource.media.type === 'pdf' ||
                resource.media.type === 'image_link') && (
                <Button
                    {...resource.media.button}
                    btn_style={style}
                    btn_size={size}
                >
                    {resource.media.button.cta_text}
                </Button>
            )}
            {resource.media.type === 'video' && (
                <>
                    <Button
                        btn_style={style}
                        btn_size={size}
                        icon={resource.media.button.icon}
                        icon_position={resource.media.button.icon_position}
                        trigger={() => setIsOpen(true)}
                    >
                        {resource.media.button.cta_text}
                    </Button>
                    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
                        <VideoModalContent
                            video={resource.media}
                            setIsOpen={setIsOpen}
                            disableModal
                        />
                    </Modal>
                </>
            )}
        </>
    );
};
