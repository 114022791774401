import dynamic from 'next/dynamic';

import { Info } from '@/blocks/info';
import { Features } from '@/blocks/features';
import { Products } from '@/blocks/products';
import { Resources } from '@/blocks/resources';
import { Tout } from '@/blocks/tout';
import { Tabs } from '@/blocks/tabs';
import { Accordion } from '@/blocks/accordion';
import { Statistics } from '@/blocks/statistics';
import { People } from '@/blocks/people';
import { Locations } from '@/blocks/locations';
import { Contact } from '@/blocks/contact';
import { Form } from '@/blocks/form';
import { Table } from '@/blocks/table';

const JobSearch = dynamic(() =>
    import('@/blocks/job-search').then((module) => module.JobSearch)
);
const Gallery = dynamic(() =>
    import('@/blocks/gallery').then((module) => module.Gallery)
);
const ThreeDViewer = dynamic(() =>
    import('@/blocks/three-d-viewer').then((module) => module.ThreeDViewer)
);

const Social = dynamic(() =>
    import('@/blocks/social').then((module) => module.Social)
);
const Toggles = dynamic(() =>
    import('@/blocks/toggles').then((module) => module.Toggles)
);
const Criteria = dynamic(() =>
    import('@/blocks/criteria').then((module) => module.Criteria)
);
const Articles = dynamic(() =>
    import('@/blocks/articles').then((module) => module.Articles)
);
const Compliance = dynamic(() =>
    import('@/blocks/compliance').then((module) => module.Compliance)
);

const Posts = dynamic(() =>
    import('@/blocks/posts').then((module) => module.Posts)
);

const IDG = dynamic(() => import('@/blocks/idg').then((module) => module.IDG));

export const ContentBlock = ({ block }) => {
    if (!block) {
        return null;
    }
    switch (block.type) {
        case 'info':
            return <Info {...block} />;
        case 'features':
            return <Features {...block} />;
        case 'products':
            return <Products {...block} />;
        case 'resources':
            return <Resources {...block} />;
        case 'tout':
            return <Tout {...block} />;
        case 'tabs':
            return <Tabs {...block} />;
        case 'accordion':
            return <Accordion {...block} />;
        case 'statistics':
            return <Statistics {...block} />;
        case 'people':
            return <People {...block} />;
        case 'gallery':
            return <Gallery {...block} />;
        case 'locations':
            return <Locations {...block} />;
        case 'contact':
            return <Contact {...block} />;
        case 'form':
            return <Form {...block} />;
        case 'articles':
            return <Articles {...block} />;
        case 'table':
            return <Table {...block} />;
        case 'three_d':
            return <ThreeDViewer {...block} />;
        case 'compliance':
            return <Compliance {...block} />;
        case 'criteria':
            return <Criteria {...block} />;
        case 'toggles':
            return <Toggles {...block} />;
        case 'social':
            return <Social {...block} />;
        case 'posts':
            return <Posts {...block} />;
        case 'job_search':
            return <JobSearch {...block} />;
        case 'idg':
            return <IDG {...block} />;
        default:
            return null;
    }
};
