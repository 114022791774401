import { Introduction } from '@/components/introduction';
import { RichText } from '@/components/rich-text';
import { Fragment } from 'react';
import classNames from 'classnames';
import { Address } from '@/blocks/contact/elements/address';
import { Phone } from '@/blocks/contact/elements/phone';
import { Email } from '@/blocks/contact/elements/email';

export const Contact2a = ({ settings, introduction, contacts, attr }) => {
    return (
        <section
            className={classNames('section last-child-border-bottom', {
                ...settings,
            })}
            {...attr}
        >
            <div className="container">
                <Introduction {...introduction} size={'sm'} />
                {contacts && (
                    <>
                        {contacts.map((item, index) => {
                            const { title, description, communications } = item;
                            return (
                                <div className="contact" key={index}>
                                    {title && <h3>{title}</h3>}
                                    {description?.value && (
                                        <RichText
                                            className={classNames({
                                                'mb-8': communications,
                                            })}
                                            data={description}
                                        />
                                    )}
                                    {communications && (
                                        <div className="grid-md-3">
                                            {communications?.map(
                                                (item, index) => {
                                                    const { type } = item;
                                                    return (
                                                        <Fragment key={index}>
                                                            {type ===
                                                                'phone' && (
                                                                <Phone
                                                                    data={item}
                                                                />
                                                            )}
                                                            {type ===
                                                                'email' && (
                                                                <Email
                                                                    data={item}
                                                                />
                                                            )}
                                                            {type ===
                                                                'address' && (
                                                                <Address
                                                                    data={item}
                                                                />
                                                            )}
                                                        </Fragment>
                                                    );
                                                }
                                            )}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </>
                )}
            </div>
        </section>
    );
};
