import { ContentBlock } from './content-block';
import { JumpLinks } from './jump-links';

export const JumpLinkContentZone = ({ blocks, jump_links }) => {
    return (
        <>
            <JumpLinks blocks={blocks} jump_links={jump_links} />

            {/* Blocks that arent part of jump links */}
            <div className="content content--jumplinks">
                {blocks.map((block, index) => {
                    if (!block?.jump_link) {
                        return <ContentBlock key={index} block={block} />;
                    }
                })}

                {/* Blocks that are part of jump links */}
                {jump_links?.map((jump_link, index) => {
                    return (
                        <section
                            id={jump_link.id}
                            className="jumppoint"
                            key={index}
                        >
                            {blocks.map((block, index) => {
                                if (block?.jump_link === jump_link.id) {
                                    return (
                                        <ContentBlock
                                            key={index}
                                            block={block}
                                        />
                                    );
                                }
                            })}
                        </section>
                    );
                })}
            </div>
        </>
    );
};
