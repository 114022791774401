import { Introduction } from '@/components/introduction';
import { InfoBody } from '@/blocks/info/elements/info-body';
import { Media } from '@/components/media';
import classNames from 'classnames';
import { getSizes } from '@/lib/utils/get-sizes';

export const Info1b = ({
    settings,
    horizontal_spacing,
    vertical_alignment,
    introduction,
    description,
    buttons,
    media,
    footnotes,
    attr,
}) => {
    return (
        <section
            className={classNames('section info', {
                ...settings,
            })}
            {...attr}
        >
            <div className="container">
                <Introduction {...introduction} />
                {(media ||
                    description?.value ||
                    buttons ||
                    footnotes?.value) && (
                    <div
                        className={classNames('grid-md-12 gap-8 gap-md-7', {
                            'info--vertical': vertical_alignment,
                        })}
                    >
                        {media && (
                            <div className="col-md-7">
                                <Media
                                    wrapperClassName={'info__media'}
                                    sizes={getSizes({
                                        desktop: 28,
                                        tablet: 55,
                                    })}
                                    media={media}
                                />
                            </div>
                        )}
                        <div
                            className={classNames({
                                'col-start-md-9 col-end-md-13':
                                    media && horizontal_spacing === 'default',
                                'col-start-md-8 col-end-md-13':
                                    media && horizontal_spacing === 'tight',
                                'col-md-9': !media,
                            })}
                        >
                            <InfoBody
                                description={description}
                                buttons={buttons}
                                footnotes={footnotes}
                            />
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};
