import { LinkResource } from './link-resource';
import { VideoResource } from './video-resource';

export const ResourceSelector = ({ resource }) => {
    switch (resource?.media?.type) {
        case 'image_link':
            return <LinkResource {...resource} />;
        case 'pdf':
            return <LinkResource {...resource} />;
        case 'video':
            return <VideoResource {...resource} />;
        default:
            return null;
    }
};
