import { Introduction } from '@/components/introduction';
import { Tabs } from '@/components/tabs';
import { TabContent } from '@/components/tabs/elements/tab-content';
import { ContentZone } from '@/components/content-zone';
import classNames from 'classnames';

export const Tabs1b = ({ settings, introduction, tabs, attr }) => {
    return (
        <section
            className={classNames('section', {
                ...settings,
            })}
            {...attr}
        >
            <div className="container">
                <Introduction {...introduction} />
                <Tabs tabs={tabs} iconStyle="left" grow icons>
                    {tabs?.map((tab, index) => {
                        const count = index + 1;
                        return (
                            <TabContent key={index} count={count}>
                                <ContentZone blocks={tab.content} />
                            </TabContent>
                        );
                    })}
                </Tabs>
            </div>
        </section>
    );
};
