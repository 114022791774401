'use client';

import { JumpLink } from './jump-link';
import { useJumpLinks } from '../hooks/use-jump-links';
import { useEffect, useRef } from 'react';

import classNames from 'classnames';
import { useWindowDimensions } from '@/lib/hooks/use-window-dimensions';

export const JumpLinks = ({ blocks, jump_links }) => {
    const { width } = useWindowDimensions();
    const { active, activeLink } = useJumpLinks({ blocks, width });
    const container = useRef();
    useEffect(() => {
        if (activeLink) {
            const link = document.querySelector(`a[href="#${activeLink}"]`);
            container.current.scroll({
                left: link.offsetLeft,
                behavior: 'smooth',
            });
        }
    }, [activeLink]);
    return (
        <nav className="jumplinks">
            <div
                className={classNames('jumplinks__wrapper', {
                    'jumplinks__wrapper--active': active,
                })}
            >
                <div className="jumplinks__container" ref={container}>
                    {jump_links?.map((jump_link, index) => {
                        return (
                            <JumpLink
                                key={index}
                                {...jump_link}
                                activeLink={activeLink}
                                width={width}
                            />
                        );
                    })}
                </div>
            </div>
        </nav>
    );
};
