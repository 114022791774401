import { Accordion1a } from '@/blocks/accordion/variants/accordion-1a';
import { Accordion1b } from '@/blocks/accordion/variants/accordion-1b';

import { Accordion2a } from '@/blocks/accordion/variants/accordion-2a';
import { Accordion2b } from '@/blocks/accordion/variants/accordion-2b';

const prefix = 'accordion';

const variants = {
    [`${prefix}_1a`]: Accordion1a,
    [`${prefix}_1b`]: Accordion1b,
    [`${prefix}_2a`]: Accordion2a,
    [`${prefix}_2b`]: Accordion2b,
};

export const Accordion = ({ ...block }) => {
    const Component = variants[block.variant];
    return Component ? <Component {...block} /> : null;
};
