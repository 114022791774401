import { Contact1a } from '@/blocks/contact/variants/contact-1a';
import { Contact2a } from '@/blocks/contact/variants/contact-2a';
import { Contact2b } from '@/blocks/contact/variants/contact-2b';

const prefix = 'contact';

const variants = {
    [`${prefix}_1a`]: Contact1a,
    [`${prefix}_2a`]: Contact2a,
    [`${prefix}_2b`]: Contact2b,
};

export const Contact = ({ ...block }) => {
    const Component = variants[block.variant];
    return Component ? <Component {...block} /> : null;
};
