import { Locations1a } from '@/blocks/locations/variants/locations-1a';
import { Locations1b } from '@/blocks/locations/variants/locations-1b';

import { Locations2a } from '@/blocks/locations/variants/locations-2a';
import { Locations2b } from '@/blocks/locations/variants/locations-2b';

const prefix = 'locations';

const variants = {
    [`${prefix}_1a`]: Locations1a,
    [`${prefix}_1b`]: Locations1b,
    [`${prefix}_2a`]: Locations2a,
    [`${prefix}_2b`]: Locations2b,
};

export const Locations = ({ ...block }) => {
    const Component = variants[block.variant];
    return Component ? <Component {...block} /> : null;
};
