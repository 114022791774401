import { Button } from '@/components/button';
import { Introduction } from '@/components/introduction';
import { Media } from '@/components/media';
import classNames from 'classnames';

export const Tout1c = ({ introduction, settings, links, icon, attr }) => {
    return (
        <section
            className={classNames('section section--roomy', {
                [`bg-${settings.theme}`]: settings.theme,
            })}
            {...attr}
        >
            <div className="container text-md-center">
                {icon && (
                    <Media
                        wrapperClassName={'mb-4'}
                        className="tout__icon"
                        media={icon}
                    />
                )}
                <Introduction {...introduction} size={'sm'} />
                {links && (
                    <div className="btn-group">
                        {links?.map((link, index) => {
                            return (
                                <Button
                                    key={index}
                                    {...link}
                                    btn_size={link.btn_size || 'lg'}
                                >
                                    {link.cta_text}
                                </Button>
                            );
                        })}
                    </div>
                )}
            </div>
        </section>
    );
};
