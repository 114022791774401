import { IntroButton } from '@/components/intro-button';
import { Card } from '@/components/card';
import { Footnotes } from '@/components/footnotes';
import classNames from 'classnames';
import { getSizes } from '@/lib/utils/get-sizes';

export const Products2b = ({
    settings,
    introduction,
    button,
    products,
    footnotes,
    attr,
}) => {
    return (
        <section
            className={classNames('section', {
                ...settings,
            })}
            {...attr}
        >
            <div className="container">
                <IntroButton introduction={introduction} button={button} />
                <div className="grid-lg-3 grid-md-2 row-gap-md-8">
                    {products.map((product, index) => {
                        return (
                            <Card
                                sizes={getSizes({ desktop: 18, tablet: 45 })}
                                key={index}
                                variant={1}
                                {...product}
                            />
                        );
                    })}
                </div>
                {footnotes?.value && (
                    <Footnotes className="mt-14" footnotes={footnotes} />
                )}
            </div>
        </section>
    );
};
