import { Media } from '@/components/media';
import { RichText } from '@/components/rich-text';
import { getSizes } from '@/lib/utils/get-sizes';

export const PeopleModal = ({ media, job_title, full_name, description }) => {
    return (
        <div className="person person--modal">
            <Media
                wrapperClassName={'person__image'}
                className="circle"
                fill
                objectFit="cover"
                media={media}
                sizes={getSizes({
                    desktop: 6,
                    tablet: 12,
                })}
            />
            <h4 className="person__fullname">{full_name}</h4>
            <RichText className="person__occupation" data={job_title} />
            <RichText className="person__description" data={description} />
        </div>
    );
};
