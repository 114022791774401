'use client';

import classNames from 'classnames';
import { Introduction } from '@/components/introduction';
import { Media } from '@/components/media';
import { Link } from '@/components/link';

export const Tout5a = ({
    introduction,
    settings,
    icon,
    center_content,
    links,
    attr,
}) => {
    return (
        <div
            className={classNames('section', {
                [`section--top-${settings.spacing_top}`]: settings.spacing_top,
                [`section--bottom-${settings.spacing_bottom}`]:
                    settings.spacing_bottom,
            })}
            {...attr}
        >
            <div className="container">
                {links && (
                    <Link
                        href={links[0]?.cta_link}
                        className={classNames('tout tout--sm', {
                            [`bg-${settings.theme}`]: settings.theme,
                            'tout--center-horizontally': center_content,
                        })}
                    >
                        {icon && (
                            <div className="tout__media--tout5a">
                                <Media className="tout__icon" media={icon} />
                            </div>
                        )}
                        <Introduction lead={introduction.lead} size={'sm'} />
                    </Link>
                )}
                {!links && (
                    <div
                        className={classNames('tout tout--sm', {
                            [`bg-${settings.theme}`]: settings.theme,
                            'tout--center': center_content,
                        })}
                    >
                        {icon && (
                            <div className="tout__media--tout5a">
                                <Media className="tout__icon" media={icon} />
                            </div>
                        )}
                        <Introduction lead={introduction.lead} size={'sm'} />
                    </div>
                )}
            </div>
        </div>
    );
};
