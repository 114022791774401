import { Introduction } from '@/components/introduction';
import { InfoBody } from '@/blocks/info/elements/info-body';
import { Media } from '@/components/media';
import classNames from 'classnames';
import { getSizes } from '@/lib/utils/get-sizes';

export const Info3a = ({
    settings,
    introduction,
    description,
    buttons,
    media,
    footnotes,
    attr,
}) => {
    return (
        <section
            className={classNames('section info', {
                ...settings,
            })}
            {...attr}
        >
            <div className="container">
                <Introduction {...introduction} size={'sm'} />
                {media && (
                    <div className={'info__media'}>
                        <Media
                            sizes={getSizes({
                                desktop: 58,
                                tablet: 97,
                            })}
                            media={media}
                        />
                    </div>
                )}
                <InfoBody
                    description={description}
                    buttons={buttons}
                    footnotes={footnotes}
                />
            </div>
        </section>
    );
};
