/**
 * Features Block
 * A features block can showcase a set of features for a specific product.
 */

import { Features1a } from '@/blocks/features/variants/features-1a';
import { Features1b } from '@/blocks/features/variants/features-1b';
import { Features1c } from '@/blocks/features/variants/features-1c';

import { Features2a } from '@/blocks/features/variants/features-2a';
import { Features2b } from '@/blocks/features/variants/features-2b';
import { Features2c } from '@/blocks/features/variants/features-2c';

import { Features3a } from '@/blocks/features/variants/features-3a';
import { Features3b } from '@/blocks/features/variants/features-3b';

import { Features4a } from '@/blocks/features/variants/features-4a';
import { Features4b } from '@/blocks/features/variants/features-4b';

const prefix = 'features';

const variants = {
    [`${prefix}_1a`]: Features1a,
    [`${prefix}_1b`]: Features1b,
    [`${prefix}_1c`]: Features1c,
    [`${prefix}_2a`]: Features2a,
    [`${prefix}_2b`]: Features2b,
    [`${prefix}_2c`]: Features2c,
    [`${prefix}_3a`]: Features3a,
    [`${prefix}_3b`]: Features3b,
    [`${prefix}_4a`]: Features4a,
    [`${prefix}_4b`]: Features4b,
};

export const Features = ({ ...block }) => {
    const Component = variants[block.variant];
    return Component ? <Component {...block} /> : null;
};
