'use client';

import { createContext, useContext, useState } from 'react';
import { Tab } from './elements/tab';
import { Dropdown } from '@/components/dropdown';
import { useExpand } from '@/lib/hooks/use-expand';
import classNames from 'classnames';

const TabsContext = createContext(null);

export const useTabsContext = () => {
    const context = useContext(TabsContext);
    return context;
};

export const Tabs = ({
    tabs,
    variant,
    className,
    iconStyle,
    grow,
    icons,
    children,
}) => {
    const [activeTab, setActiveTab] = useState(1);
    const objectData = tabs?.map((tab, index) => {
        const count = index + 1;
        const { title, media } = tab;
        return { title, media, id: count };
    });
    const onSelectChange = (item) => {
        setActiveTab(item);
    };
    const { expand } = useExpand();

    return (
        <TabsContext.Provider
            value={{
                activeTab,
                expand,
            }}
        >
            <div
                className={classNames('tabs', {
                    [`${className}`]: className,
                })}
            >
                <Dropdown
                    className="dropdown--tabs"
                    objectData={objectData}
                    onSelectChange={onSelectChange}
                    icon={icons}
                />

                <div className={classNames('tabs__menu')}>
                    {tabs?.map((tab, index) => {
                        const count = index + 1;
                        return (
                            <Tab
                                key={count}
                                tab={tab}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                                variant={variant}
                                iconStyle={iconStyle}
                                grow={grow}
                                tabID={count}
                            />
                        );
                    })}
                </div>

                <div className="tabs__content">{children}</div>
            </div>
        </TabsContext.Provider>
    );
};
