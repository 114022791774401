import { Introduction } from '@/components/introduction';
import { InfoBody } from '@/blocks/info/elements/info-body';
import { Media } from '@/components/media';
import classNames from 'classnames';
import { getSizes } from '@/lib/utils/get-sizes';

export const Info1e = ({
    settings,
    horizontal_spacing,
    vertical_alignment,
    introduction,
    description,
    buttons,
    media,
    footnotes,
    attr,
}) => {
    return (
        <section
            className={classNames('section info', {
                ...settings,
            })}
            {...attr}
        >
            <div className="container">
                <div
                    className={classNames('grid-lg-12', {
                        'info--vertical': vertical_alignment,
                    })}
                >
                    {media && (
                        <div className="col-lg-7 d-none d-lg-block">
                            <Media
                                sizes={getSizes({
                                    desktop: 28,
                                    tablet: 55,
                                })}
                                media={media}
                            />
                        </div>
                    )}
                    <div
                        className={classNames({
                            'col-start-lg-9 col-end-lg-13':
                                media && horizontal_spacing === 'default',
                            'col-start-lg-8 col-end-lg-13':
                                media && horizontal_spacing === 'tight',
                            'col-lg-9': !media,
                        })}
                    >
                        <Introduction {...introduction} size={'sm'} />
                        {media && (
                            <div className="info__media d-block d-lg-none">
                                <Media
                                    sizes={getSizes({
                                        desktop: 28,
                                        tablet: 55,
                                    })}
                                    media={media}
                                />
                            </div>
                        )}
                        <InfoBody
                            description={description}
                            buttons={buttons}
                            footnotes={footnotes}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};
