import { Media } from '@/components/media';
import { Footnotes } from '@/components/footnotes';
import { Feature } from '../elements/feature';
import classNames from 'classnames';
import { IntroButton } from '@/components/intro-button';
import { getSizes } from '@/lib/utils/get-sizes';

export const Features1b = ({
    settings,
    center_items,
    elevation,
    offset_media,
    introduction,
    button,
    media,
    features,
    footnotes,
    attr,
}) => {
    return (
        <section
            className={classNames('section features', {
                ...settings,
            })}
            {...attr}
        >
            <div className="container">
                <IntroButton introduction={introduction} button={button} />
                {media && (
                    <div className="features__media">
                        <Media
                            media={media}
                            sizes={getSizes({
                                desktop: 58,
                                tablet: 97,
                            })}
                        />
                    </div>
                )}

                <div
                    className={classNames('grid-md-3 features__items', {
                        'row-gap-md-12': !offset_media,
                        'row-gap-20 row-gap-md-24 mt-22': offset_media,
                    })}
                >
                    {features?.map((feature, index) => {
                        return (
                            <Feature
                                key={index}
                                center_items={center_items}
                                elevation={elevation}
                                offset_media={offset_media}
                                variant={1}
                                {...feature}
                            />
                        );
                    })}
                </div>
                {footnotes?.value && <Footnotes footnotes={footnotes} />}
            </div>
        </section>
    );
};
