// import { useSearchParams } from 'next/navigation';

/**
 * Used for vault pdf submissions. If ?expand=true is added to the URL of a page it will expand all Accordions, Tabs, Gallery, and hide the floating ISI
 * @returns expand
 */

export const useExpand = () => {
    // const searchParams = useSearchParams();
    // const expand = searchParams.get('expand') === 'true';
    return { expand: false };
};
