import { JumpLinkContentZone } from '@/components/content-zone/elements/jump-link-content-zone';
import { NormalContentZone } from '@/components/content-zone/elements/normal-content-zone';

export const ContentZone = ({ blocks, jump_links }) => {
    if (!blocks) {
        return null;
    }
    return (
        <>
            {!jump_links && <NormalContentZone blocks={blocks} />}
            {jump_links && (
                <JumpLinkContentZone blocks={blocks} jump_links={jump_links} />
            )}
        </>
    );
};
