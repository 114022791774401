import { IntroButton } from '@/components/intro-button';
import { Footnotes } from '@/components/footnotes';
import { RichText } from '@/components/rich-text';
import classNames from 'classnames';

export const Statistics3b = ({
    settings,
    introduction,
    statistics,
    button,
    footnotes,
    attr,
}) => {
    return (
        <section
            className={classNames('section', {
                ...settings,
            })}
            {...attr}
        >
            <div className="container">
                <IntroButton introduction={introduction} button={button} />
                <div className={'grid-lg-3 grid-md-2 row-gap-2 row-gap-md-7'}>
                    {statistics.map((statistic, index) => {
                        const {
                            id,
                            codename,
                            title,
                            description,
                            stat,
                            theme,
                        } = statistic;
                        return (
                            <article
                                key={index}
                                className={classNames(
                                    'statistic statistic--2',
                                    {
                                        [`bg-${theme}`]: theme,
                                    }
                                )}
                                data-kontent-item-id={id}
                                data-kontent-element-codename={codename}
                                data-kontent-add-button={!!codename}
                                data-kontent-add-button-render-position="right"
                                data-kontent-add-button-insert-position="after"
                            >
                                {stat && (
                                    <div className="statistic__stat statistic__stat--small">
                                        {stat}
                                    </div>
                                )}
                                {title && (
                                    <RichText
                                        className="statistic__title"
                                        data={title}
                                    />
                                )}
                                {description && (
                                    <RichText
                                        className="statistic__description"
                                        data={description}
                                    />
                                )}
                            </article>
                        );
                    })}
                </div>

                {footnotes?.value && (
                    <Footnotes className="mt-14" footnotes={footnotes} />
                )}
            </div>
        </section>
    );
};
