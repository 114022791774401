import { Icon } from '@/components/icon';
import { RichText } from '@/components/rich-text';
import parse from 'html-react-parser';

export const Email = ({ data }) => {
    const { id, codename, email_address, email_address_v2, description } = data;
    return (
        <div
            className="contact__item"
            data-kontent-item-id={id}
            data-kontent-element-codename={codename}
        >
            <Icon
                icon={'fa-thin fa-envelope'}
                wrapperIconClassName={'contact__icon-wrapper'}
                className="contact__icon contact__icon--email"
            />
            <div className="contact__item-body">
                {description && (
                    <RichText
                        className="contact__description"
                        data={description}
                    />
                )}
                {email_address_v2 && (
                    <div className="contact__email">
                        {parse(email_address_v2)}
                    </div>
                )}
                {!email_address_v2 && (
                    <a
                        href={`mailto:${email_address}`}
                        className="contact__email"
                    >
                        {email_address && email_address}
                    </a>
                )}
            </div>
        </div>
    );
    a;
};
