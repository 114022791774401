import { Products1a } from '@/blocks/products/variants/products-1a';

import { Products2a } from '@/blocks/products/variants/products-2a';
import { Products2b } from '@/blocks/products/variants/products-2b';
import { Products2c } from '@/blocks/products/variants/products-2c';

import { Products3a } from '@/blocks/products/variants/products-3a';
import { Products3b } from '@/blocks/products/variants/products-3b';
import { Products3c } from '@/blocks/products/variants/products-3c';

import { Products4a } from '@/blocks/products/variants/products-4a';
import { Products4b } from '@/blocks/products/variants/products-4b';

const prefix = 'products';

const variants = {
    [`${prefix}_1a`]: Products1a,
    [`${prefix}_2a`]: Products2a,
    [`${prefix}_2b`]: Products2b,
    [`${prefix}_2c`]: Products2c,
    [`${prefix}_3a`]: Products3a,
    [`${prefix}_3b`]: Products3b,
    [`${prefix}_3c`]: Products3c,
    [`${prefix}_4a`]: Products4a,
    [`${prefix}_4b`]: Products4b,
};

export const Products = ({ ...block }) => {
    const Component = variants[block.variant];
    return Component ? <Component {...block} /> : null;
};
