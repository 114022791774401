import classNames from 'classnames';
import { Button } from '@/components/button';
import { Introduction } from '@/components/introduction';
import { Media } from '@/components/media';

export const Tout3e = ({
    introduction,
    settings,
    links,
    icon,
    media,
    attr,
}) => {
    return (
        <div
            className={classNames(
                `section tout tout--image section--roomy tout--roomy`,
                {
                    [`bg-${settings.theme}`]: settings.theme,
                }
            )}
            {...attr}
        >
            <div className="container tout__content">
                <div className="grid-md-12">
                    <div className="col-start-md-1 col-end-md-8">
                        {icon && (
                            <Media
                                wrapperClassName={'mb-4'}
                                className="tout__icon"
                                media={icon}
                            />
                        )}
                        <Introduction {...introduction} size={'sm'} />
                        {links && (
                            <div className="btn-group">
                                {links?.map((link, index) => {
                                    return (
                                        <Button
                                            key={index}
                                            {...link}
                                            btn_size={link.btn_size || 'lg'}
                                        >
                                            {link.cta_text}
                                        </Button>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {media && (
                <>
                    <div className="tout__media-wrapper">
                        <Media
                            wrapperClassName={'tout__media'}
                            fill
                            objectFit={'cover'}
                            objectPosition="top right"
                            media={media}
                            disableCaption
                            disableElevation
                        />
                    </div>
                    <Media
                        wrapperClassName={'d-block d-md-none mt-8'}
                        media={media}
                        disableCaption
                        disableElevation
                    />
                </>
            )}
        </div>
    );
};
