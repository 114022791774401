import { RichText } from '@/components/rich-text';
import classNames from 'classnames';

export const Introduction = ({
    eyebrow,
    title,
    title_color,
    title_alignment,
    lead,
    lead_style,
    introduction_style,
    className,
    size,
}) => {
    return (
        <>
            {(title?.value || lead?.value) && (
                <div
                    className={classNames('introduction', {
                        [`${className}`]: className,
                        [`introduction--sm`]: size === 'sm',
                        [`introduction--xs`]: size === 'xs',
                        [`introduction--accent`]:
                            introduction_style === 'accent',
                    })}
                >
                    {eyebrow && (
                        <div
                            className={classNames('eyebrow', {
                                ['text-center']: title_alignment === 'center',
                            })}
                        >
                            {eyebrow}
                        </div>
                    )}
                    {title?.value && (
                        <RichText
                            className={classNames('introduction__title', {
                                [`introduction__title--${title_color}`]:
                                    title_color,
                                ['text-center']: title_alignment === 'center',
                            })}
                            data={title}
                        />
                    )}
                    {lead?.value && lead_style === 'lead' && (
                        <RichText className="lead" data={lead} />
                    )}
                    {lead?.value && lead_style === 'rich_text' && (
                        <RichText data={lead} />
                    )}
                    {lead?.value && !lead_style && (
                        <RichText className="lead" data={lead} />
                    )}
                </div>
            )}
        </>
    );
};
