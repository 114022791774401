import { Accordion } from '@/components/accordion';
import { Introduction } from '@/components/introduction';
import { Footnotes } from '@/components/footnotes';
import { Button } from '@/components/button';
import classNames from 'classnames';

export const Accordion1a = ({
    settings,
    introduction,
    items,
    button,
    footnotes,
    attr,
}) => {
    return (
        <section
            className={classNames('section', {
                ...settings,
            })}
            {...attr}
        >
            <div className="container">
                <Introduction {...introduction} size="sm" />
                {button && (
                    <Button className="mb-12 mb-lg-14" {...button}>
                        {button?.cta_text}
                    </Button>
                )}
                <Accordion items={items} />
                {footnotes?.value && (
                    <Footnotes className="mt-14" footnotes={footnotes} />
                )}
            </div>
        </section>
    );
};
