import { Tile } from '@/components/tile';
import { Introduction } from '@/components/introduction';
import { Footnotes } from '@/components/footnotes';
import classNames from 'classnames';

export const Resources4a = ({
    settings,
    introduction,
    footnotes,
    resources,
    attr,
}) => {
    return (
        <section
            className={classNames('section', {
                ...settings,
            })}
            {...attr}
        >
            <div className="container">
                <Introduction {...introduction} />
                {resources.length !== 0 && (
                    <div className="grid-lg-3 grid-md-2">
                        {resources.map((resource, index) => {
                            return (
                                <Tile
                                    key={index}
                                    theme={settings.theme}
                                    variant={2}
                                    {...resource}
                                />
                            );
                        })}
                    </div>
                )}
                {footnotes?.value && (
                    <Footnotes className="mt-14" footnotes={footnotes} />
                )}
            </div>
        </section>
    );
};
