import { Introduction } from '@/components/introduction';
import { Footnotes } from '@/components/footnotes';
import classNames from 'classnames';
import { ResourceButtons } from '../elements/resource-buttons';

export const Resources2b = ({
    settings,
    introduction,
    footnotes,
    resources,
    attr,
}) => {
    return (
        <section
            className={classNames('section', {
                ...settings,
            })}
            {...attr}
        >
            <div className="container">
                <Introduction {...introduction} />
                <div className="grid-md-3">
                    {resources.map((resource, index) => {
                        return (
                            <ResourceButtons
                                key={index}
                                index={index}
                                resource={resource}
                            />
                        );
                    })}
                </div>
                {footnotes?.value && (
                    <Footnotes className="mt-14" footnotes={footnotes} />
                )}
            </div>
        </section>
    );
};
