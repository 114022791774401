import Script from 'next/script';

export const MarketoScript = () => {
    return (
        <Script
            src="https://info.edwards.com/js/forms2/js/forms2.min.js"
            strategy="lazyOnload"
        />
    );
};
