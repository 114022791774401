import { IntroButton } from '@/components/intro-button';
import { Footnotes } from '@/components/footnotes';
import classNames from 'classnames';
import { Product } from '../elements/product';
import { getSizes } from '@/lib/utils/get-sizes';

export const Products1a = ({
    settings,
    introduction,
    button,
    products,
    footnotes,
    attr,
}) => {
    return (
        <section
            className={classNames('section', {
                ...settings,
            })}
            {...attr}
        >
            <div className="container">
                <IntroButton introduction={introduction} button={button} />
                {products?.map((product, index) => {
                    return (
                        <Product
                            key={index}
                            mediaSizes={getSizes({
                                desktop: 18,
                                tablet: 30,
                            })}
                            {...product}
                        />
                    );
                })}
                {footnotes?.value && (
                    <Footnotes className="mt-14" footnotes={footnotes} />
                )}
            </div>
        </section>
    );
};
