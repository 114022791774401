import React from 'react';
import { Media } from '@/components/media';
import classNames from 'classnames';
import { Link } from '@/components/link';
import { Footnotes } from '@/components/footnotes';
import { RichText } from '@/components/rich-text';
import { Icon } from '@/components/icon';

export const Card = ({
    id,
    codename,
    theme,
    className,
    title,
    sizes,
    media,
    description,
    button,
    footnotes,
    variant,
}) => {
    return (
        <Link
            href={button?.cta_link}
            className={classNames('card', {
                [`bg-${theme}`]: theme,
                [`${className}`]: className,
                [`card--${variant}`]: variant,
            })}
        >
            <Media
                sizes={sizes}
                wrapperClassName={'card__image'}
                media={media}
                disableElevation
            />
            <div
                className="card__body"
                data-kontent-item-id={id}
                data-kontent-element-codename={codename}
            >
                {title && (
                    <RichText
                        className={'card__title'}
                        data={{ value: `<h4>${title}</h4>` }}
                    />
                )}
                {description?.value && (
                    <RichText
                        className="card__description"
                        data={
                            variant === 2
                                ? {
                                      value: `${description.value} <div class="card__footnotes card__footnotes--hoverable">${footnotes.value}</div>`,
                                  }
                                : description
                        }
                    />
                )}
                {footnotes?.value && (
                    <Footnotes
                        className="card__footnotes"
                        footnotes={footnotes}
                    />
                )}
                {description?.value && (
                    <Icon
                        wrapperIconClassName={'card__arrow-wrapper'}
                        className="card__arrow"
                        icon="fa-regular fa-circle-arrow-right"
                    />
                )}
            </div>
        </Link>
    );
};
