import { Media } from '@/components/media';
import classNames from 'classnames';
import { RichText } from '@/components/rich-text';
import { Footnotes } from '@/components/footnotes';

export const Feature = ({
    id,
    codename,
    media,
    secondary_media,
    description,
    title,
    footnotes,
    center_items,
    elevation,
    offset_media,
    variant,
    layout,
}) => {
    const getLayout = () => {
        if (offset_media) {
            return 'fill';
        }
        if (layout === 'intrinsic') {
            return 'intrinsic';
        }
    };
    return (
        <article
            className={classNames('feature', {
                'feature--center': center_items,
                'feature--elevated': elevation || offset_media,
                'feature--offset-media': offset_media,
                [`feature--${variant}`]: variant,
            })}
            data-kontent-item-id={id}
            data-kontent-element-codename={codename}
        >
            {media && (
                <div
                    className={classNames('feature__media', {
                        'feature__media--native': media?.native,
                    })}
                >
                    <Media
                        media={media}
                        icon_size={'4x'}
                        layout={getLayout()}
                        objectFit="contain"
                        className={'feature__media-item'}
                        wrapperClassName={
                            offset_media && 'feature__media-wrapper'
                        }
                        wrapperIconClassName={'feature__icon'}
                        disableCaption
                    />
                </div>
            )}
            {(title?.value || description?.value || footnotes?.value) && (
                <div className="feature__content">
                    {title?.value && (
                        <RichText className={'feature__title'} data={title} />
                    )}
                    {description?.value && (
                        <RichText
                            className={'feature__description'}
                            data={description}
                        />
                    )}
                    {footnotes?.value && <Footnotes footnotes={footnotes} />}
                </div>
            )}
            {variant === 3 && (
                <>
                    {secondary_media && (
                        <div
                            className={classNames(
                                'feature__media feature__media--secondary',
                                {
                                    'feature__media--native':
                                        media?.image?.native,
                                }
                            )}
                        >
                            <Media
                                media={secondary_media}
                                icon_size={'4x'}
                                wrapperIconClassName={'feature__icon'}
                                disableCaption
                            />
                        </div>
                    )}
                </>
            )}
        </article>
    );
};
