import classNames from 'classnames';
import { getVisibilityConditions } from '@/lib/utils/get-form-element-fields';
import { useEffect } from 'react';

export const TextArea = ({
    register,
    label,
    name,
    visibility_conditions,
    validation,
    data,
    errors,
    setValue,
    reset,
    id,
    codename,
}) => {
    validation = {
        ...validation,
        pattern: {
            value: validation?.pattern
                ? new RegExp(validation.pattern.value)
                : null,
            message: validation?.pattern ? validation?.pattern?.message : null,
        },
    };
    useEffect(() => {
        if (data[reset]) {
            setValue(name, '');
        }
    }, [data[reset]]);
    return (
        getVisibilityConditions({ data, visibility_conditions }) && (
            <div
                className="form__field form__field--full"
                data-kontent-item-id={id}
                data-kontent-element-codename={codename}
            >
                <label className="form__label">
                    <div>{label}</div>
                    <textarea
                        className={classNames(
                            'form__input form__input--textarea',
                            {
                                'form__input--error': errors[name],
                            }
                        )}
                        {...register(name, validation)}
                    />
                </label>
                {errors[name] && (
                    <div className="form__helper">{errors[name]?.message}</div>
                )}
            </div>
        )
    );
};
