import { Accordion } from '@/components/accordion';
import { Introduction } from '@/components/introduction';
import { Footnotes } from '@/components/footnotes';
import { Button } from '@/components/button';
import classNames from 'classnames';

export const Accordion1b = ({
    settings,
    introduction,
    items,
    button,
    footnotes,
    attr,
}) => {
    return (
        <section
            className={classNames('section', {
                ...settings,
            })}
            {...attr}
        >
            <div className="container">
                <div className="grid-lg-12 gap-0 gap-md-7">
                    <div className="col-lg-4">
                        <Introduction {...introduction} size="sm" />
                        {button && (
                            <Button className="mb-12 mb-lg-0" {...button}>
                                {button?.cta_text}
                            </Button>
                        )}
                        {footnotes?.value && (
                            <Footnotes
                                className="d-none d-lg-block mt-14"
                                footnotes={footnotes}
                            />
                        )}
                    </div>
                    <div className="col-start-lg-6 col-end-lg-13">
                        <Accordion items={items} />
                        {footnotes?.value && (
                            <Footnotes
                                className="d-block d-lg-none mt-14"
                                footnotes={footnotes}
                            />
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};
