import { Icon } from '@/components/icon';
import { RichText } from '@/components/rich-text';

export const Address = ({ data }) => {
    const { id, codename, description, address } = data;
    return (
        <div
            className="contact__item"
            data-kontent-item-id={id}
            data-kontent-element-codename={codename}
        >
            <Icon
                icon={'fa-thin fa-map-location-dot'}
                wrapperIconClassName={'contact__icon-wrapper'}
                className="contact__icon contact__icon--address"
            />
            <div className="contact__item-body">
                {description && (
                    <RichText
                        className="contact__description"
                        data={description}
                    />
                )}
                {address && (
                    <RichText className="contact__address" data={address} />
                )}
            </div>
        </div>
    );
};
