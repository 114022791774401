import { Introduction } from '@/components/introduction';
import { InfoBody } from '@/blocks/info/elements/info-body';
import { Media } from '@/components/media';
import classNames from 'classnames';
import { getSizes } from '@/lib/utils/get-sizes';

export const Info3b = ({
    settings,
    introduction,
    description,
    buttons,
    media,
    footnotes,
    attr,
}) => {
    return (
        <section
            className={classNames('section info', {
                ...settings,
            })}
            {...attr}
        >
            <div className="container">
                <div className="bg-white p-4 p-md-8">
                    <Introduction {...introduction} size={'sm'} />
                    {media && (
                        <div className={'info__media'}>
                            <Media
                                sizes={getSizes({
                                    desktop: 55,
                                    tablet: 91,
                                })}
                                media={media}
                            />
                        </div>
                    )}
                    <InfoBody
                        description={description}
                        buttons={buttons}
                        buttonBlock
                        footnotes={footnotes}
                    />
                </div>
            </div>
        </section>
    );
};
