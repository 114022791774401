import React from 'react';
import { Text } from '@/blocks/form/elements/text';
import { Radio } from '@/blocks/form/elements/radio';
import { Checkbox } from '@/blocks/form/elements/checkbox';
import { Select } from '@/blocks/form/elements/select';
import { TextArea } from '@/blocks/form/elements/text-area';
import { Hidden } from '@/blocks/form/elements/hidden';
import parse from 'html-react-parser';
import { getVisibilityConditions } from './get-form-element-fields';
import classNames from 'classnames';

export function renderFormFields(
    field,
    register,
    handleSubmit,
    control,
    setValue,
    data,
    errors,
    step,
    index
) {
    switch (field?.type) {
        case 'text':
        case 'tel':
        case 'email':
        case 'date':
        case 'time':
        case 'password':
            return (
                <Text
                    key={field.id}
                    {...field}
                    setValue={setValue}
                    register={register}
                    data={data}
                    errors={errors}
                />
            );
        case 'select':
            return (
                <Select
                    key={field.id}
                    {...field}
                    setValue={setValue}
                    control={control}
                    register={register}
                    data={data}
                    errors={errors}
                />
            );

        case 'radio':
            return (
                <Radio
                    key={field.id}
                    {...field}
                    setValue={setValue}
                    register={register}
                    data={data}
                    errors={errors}
                />
            );
        case 'checkbox':
            return (
                <Checkbox
                    key={field.id}
                    {...field}
                    setValue={setValue}
                    register={register}
                    data={data}
                    errors={errors}
                />
            );
        case 'text_area':
            return (
                <TextArea
                    key={field.id}
                    {...field}
                    setValue={setValue}
                    register={register}
                    data={data}
                    errors={errors}
                />
            );
        case 'rich_text':
            return (
                getVisibilityConditions({
                    data,
                    visibility_conditions: field.visibility_conditions,
                }) && (
                    <div
                        key={field.id}
                        data-kontent-item-id={field.id}
                        data-kontent-element-codename={field.codename}
                        className={classNames('form__field form__field--full', {
                            'form__field--small': field.element === 'small',
                        })}
                    >
                        {parse(field.content)}
                    </div>
                )
            );
        case 'horizontal_rule':
            return (
                <hr
                    key={field.id}
                    className="form__field form__field--full mt-2 mb-2"
                />
            );
        case 'hidden':
            return (
                <Hidden
                    key={field.id}
                    {...field}
                    setValue={setValue}
                    register={register}
                    data={data}
                    errors={errors}
                />
            );
        case 'form_field_group':
            return (
                getVisibilityConditions({
                    data,
                    visibility_conditions: field.visibility_conditions,
                }) &&
                field?.fields.map((field) => {
                    if (field?.type === null) return null;
                    return renderFormFields(
                        field,
                        register,
                        handleSubmit,
                        control,
                        setValue,
                        data,
                        errors
                    );
                })
            );
        case 'form_step':
            return (
                <div
                    className={classNames('form__step', {
                        'd-none': step !== index,
                    })}
                >
                    {field?.fields.map((field) => {
                        if (field?.type === null) return null;
                        return renderFormFields(
                            field,
                            register,
                            handleSubmit,
                            control,
                            setValue,
                            data,
                            errors
                        );
                    })}
                </div>
            );
        default:
            return null;
    }
}
