import { People1a } from '@/blocks/people/variants/people-1a';
import { People1b } from '@/blocks/people/variants/people-1b';
import { People1c } from '@/blocks/people/variants/people-1c';

import { People2a } from '@/blocks/people/variants/people-2a';
import { People2b } from '@/blocks/people/variants/people-2b';
import { People2c } from '@/blocks/people/variants/people-2c';

import { People3a } from '@/blocks/people/variants/people-3a';
import { People3b } from '@/blocks/people/variants/people-3b';
import { People3c } from '@/blocks/people/variants/people-3c';

import { People4a } from '@/blocks/people/variants/people-4a';
import { People4b } from '@/blocks/people/variants/people-4b';
import { People4c } from '@/blocks/people/variants/people-4c';

const prefix = 'people';

const variants = {
    [`${prefix}_1a`]: People1a,
    [`${prefix}_1b`]: People1b,
    [`${prefix}_1c`]: People1c,
    [`${prefix}_2a`]: People2a,
    [`${prefix}_2b`]: People2b,
    [`${prefix}_2c`]: People2c,
    [`${prefix}_3a`]: People3a,
    [`${prefix}_3b`]: People3b,
    [`${prefix}_3c`]: People3c,
    [`${prefix}_4a`]: People4a,
    [`${prefix}_4b`]: People4b,
    [`${prefix}_4c`]: People4c,
};

export const People = ({ ...block }) => {
    const Component = variants[block.variant];
    return Component ? <Component {...block} /> : null;
};
