import { Resources1a } from '@/blocks/resources/variants/resources-1a';
import { Resources1b } from '@/blocks/resources/variants/resources-1b';
import { Resources1c } from '@/blocks/resources/variants/resources-1c';

import { Resources2a } from '@/blocks/resources/variants/resources-2a';
import { Resources2b } from '@/blocks/resources/variants/resources-2b';
import { Resources2c } from '@/blocks/resources/variants/resources-2c';
import { Resources2d } from '@/blocks/resources/variants/resources-2d';

import { Resources3a } from '@/blocks/resources/variants/resources-3a';
import { Resources3b } from '@/blocks/resources/variants/resources-3b';

import { Resources4a } from '@/blocks/resources/variants/resources-4a';
import { Resources4b } from '@/blocks/resources/variants/resources-4b';

const prefix = 'resources';

const variants = {
    [`${prefix}_1a`]: Resources1a,
    [`${prefix}_1b`]: Resources1b,
    [`${prefix}_1c`]: Resources1c,
    [`${prefix}_2a`]: Resources2a,
    [`${prefix}_2b`]: Resources2b,
    [`${prefix}_2c`]: Resources2c,
    [`${prefix}_2d`]: Resources2d,
    [`${prefix}_3a`]: Resources3a,
    [`${prefix}_3b`]: Resources3b,
    [`${prefix}_4a`]: Resources4a,
    [`${prefix}_4b`]: Resources4b,
};

export const Resources = ({ ...block }) => {
    const Component = variants[block.variant];
    return Component ? <Component {...block} /> : null;
};
