import { Media } from '@/components/media';
import { getSizes } from '@/lib/utils/get-sizes';

export const Mosaic = ({ images }) => {
    return (
        <div className="mosaic">
            {images.map((image, index) => {
                if (index === 0 || index === 3) {
                    return (
                        <Media
                            wrapperClassName={`mosaic__item mosaic__item--${index}`}
                            key={index}
                            fill
                            objectFit={'cover'}
                            objectPosition={'center'}
                            media={image}
                            disableCaption
                            disableElevation
                            sizes={getSizes({
                                desktop: 24,
                                tablet: 39,
                            })}
                        />
                    )
                } else if (index === 1 || index === 2) {
                    return (
                        <Media
                            wrapperClassName={`mosaic__item mosaic__item--${index}`}
                            key={index}
                            fill
                            objectFit={'cover'}
                            objectPosition={'center'}
                            media={image}
                            disableCaption
                            disableElevation
                            sizes={getSizes({
                                desktop: 9,
                                tablet: 17,
                            })}
                        />
                    )
                }
            })}
        </div>
    );
};
