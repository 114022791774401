import { Table1a } from '@/blocks/table/variants/table-1a';

const prefix = 'table';

const variants = {
    [`${prefix}_1a`]: Table1a,
};

export const Table = ({ ...block }) => {
    const Component = variants[block.variant];
    return Component ? <Component {...block} /> : null;
};
