import { Introduction } from '@/components/introduction';
import { useForm } from '@/lib/hooks/use-form';
import { Media } from '@/components/media';
import { Footnotes } from '@/components/footnotes';
import { RichText } from '@/components/rich-text';
import classNames from 'classnames';
import { useRef } from 'react';
import { Results } from '../elements/results';
import { MarketoScript } from '../elements/marketo-script';
import { Form } from '../elements/form';
import { getSizes } from '@/lib/utils/get-sizes';

export const Form5a = ({
    settings,
    introduction,
    description,
    media,
    form,
    form_element,
    footnotes,
    thank_you_message,
    attr,
}) => {
    const ref = useRef();
    const { success, error, isPending, results, handleSubmit } = useForm({
        form,
        ref,
        form_element,
    });
    return (
        <>
            <MarketoScript />
            <section
                className={classNames('section form__wrapper', {
                    ...settings,
                })}
                {...attr}
                ref={ref}
                data-form={form}
            >
                <div className="container">
                    <div
                        className={classNames('formcard', {
                            [`bg-${form_element.theme}`]: form_element.theme,
                            'formcard--centered': !media,
                        })}
                    >
                        <div
                            className={classNames('formcard__content', {
                                'formcard__content--centered': !media,
                            })}
                        >
                            {!success && (
                                <>
                                    <div
                                        className={classNames({
                                            'text-center': !media,
                                        })}
                                    >
                                        <Introduction
                                            {...introduction}
                                            size="sm"
                                        />
                                        <RichText
                                            className="formcard__description"
                                            data={description}
                                        />
                                    </div>
                                    <Form
                                        form={form_element}
                                        isPending={isPending}
                                        submit={handleSubmit}
                                    />
                                    {footnotes?.value && (
                                        <Footnotes
                                            className="mt-10"
                                            footnotes={footnotes}
                                        />
                                    )}
                                    {error && (
                                        <RichText
                                            className="form__error"
                                            data={{ value: error }}
                                        />
                                    )}
                                </>
                            )}
                            {success && (
                                <Results
                                    success={success}
                                    form_element={form_element}
                                    thank_you_message={thank_you_message}
                                    results={results}
                                />
                            )}
                        </div>
                        {media && (
                            <Media
                                wrapperClassName={'formcard__media'}
                                fill
                                objectFit="cover"
                                media={media}
                                disableElevation
                                disableCaption
                                sizes={getSizes({
                                    desktop: 28,
                                    tablet: 97,
                                })}
                            />
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};
