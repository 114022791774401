import { Statistics1a } from '@/blocks/statistics/variants/statistics-1a';
import { Statistics1b } from '@/blocks/statistics/variants/statistics-1b';
import { Statistics1c } from '@/blocks/statistics/variants/statistics-1c';

import { Statistics2a } from '@/blocks/statistics/variants/statistics-2a';
import { Statistics2b } from '@/blocks/statistics/variants/statistics-2b';
import { Statistics2c } from '@/blocks/statistics/variants/statistics-2c';

import { Statistics3a } from '@/blocks/statistics/variants/statistics-3a';
import { Statistics3b } from '@/blocks/statistics/variants/statistics-3b';

import { Statistics4a } from '@/blocks/statistics/variants/statistics-4a';
import { Statistics4b } from '@/blocks/statistics/variants/statistics-4b';

const prefix = 'statistics';

const variants = {
    [`${prefix}_1a`]: Statistics1a,
    [`${prefix}_1b`]: Statistics1b,
    [`${prefix}_1c`]: Statistics1c,
    [`${prefix}_2a`]: Statistics2a,
    [`${prefix}_2b`]: Statistics2b,
    [`${prefix}_2c`]: Statistics2c,
    [`${prefix}_3a`]: Statistics3a,
    [`${prefix}_3b`]: Statistics3b,
    [`${prefix}_4a`]: Statistics4a,
    [`${prefix}_4b`]: Statistics4b,
};

export const Statistics = ({ ...block }) => {
    const Component = variants[block.variant];
    return Component ? <Component {...block} /> : null;
};
