import { Info1a } from '@/blocks/info/variants/info-1a';
import { Info1b } from '@/blocks/info/variants/info-1b';
import { Info1c } from '@/blocks/info/variants/info-1c';
import { Info1d } from '@/blocks/info/variants/info-1d';
import { Info1e } from '@/blocks/info/variants/info-1e';
import { Info1f } from '@/blocks/info/variants/info-1f';

import { Info2a } from '@/blocks/info/variants/info-2a';
import { Info2b } from '@/blocks/info/variants/info-2b';
import { Info2c } from '@/blocks/info/variants/info-2c';
import { Info2d } from '@/blocks/info/variants/info-2d';
import { Info2e } from '@/blocks/info/variants/info-2e';
import { Info2f } from '@/blocks/info/variants/info-2f';

import { Info3a } from '@/blocks/info/variants/info-3a';
import { Info3b } from '@/blocks/info/variants/info-3b';

import { Info4a } from '@/blocks/info/variants/info-4a';

const prefix = 'info';

const variants = {
    [`${prefix}_1a`]: Info1a,
    [`${prefix}_1b`]: Info1b,
    [`${prefix}_1c`]: Info1c,
    [`${prefix}_1d`]: Info1d,
    [`${prefix}_1e`]: Info1e,
    [`${prefix}_1f`]: Info1f,
    [`${prefix}_2a`]: Info2a,
    [`${prefix}_2b`]: Info2b,
    [`${prefix}_2c`]: Info2c,
    [`${prefix}_2d`]: Info2d,
    [`${prefix}_2e`]: Info2e,
    [`${prefix}_2f`]: Info2f,
    [`${prefix}_3a`]: Info3a,
    [`${prefix}_3b`]: Info3b,
    [`${prefix}_4a`]: Info4a,
};

export const Info = ({ ...block }) => {
    const Component = variants[block.variant];
    return Component ? <Component {...block} /> : null;
};
