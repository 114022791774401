import { RichText } from '@/components/rich-text';
import classNames from 'classnames';

export const Footnotes = ({ footnotes, className }) => {
    return (
        <RichText
            className={classNames('footnotes', {
                [`${className}`]: className,
            })}
            data={footnotes}
        />
    );
};
