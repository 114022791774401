import { Media } from '@/components/media';
import { Footnotes } from '@/components/footnotes';
import { Feature } from '../elements/feature';
import classNames from 'classnames';
import { IntroButton } from '@/components/intro-button';
import { getSizes } from '@/lib/utils/get-sizes';

export const Features2a = ({
    settings,
    center_items,
    elevation,
    offset_media,
    introduction,
    button,
    media,
    features,
    footnotes,
    attr,
}) => {
    return (
        <section
            className={classNames('section features', {
                ...settings,
            })}
            {...attr}
        >
            <div className="container">
                <IntroButton introduction={introduction} button={button} />
                <div
                    className={classNames(
                        'grid-md-12 row-gap-14 features__items',
                        {
                            'mt-md-6': offset_media,
                        }
                    )}
                >
                    <div className="col-md-5 order-2 order-md-1">
                        <div
                            className={classNames('grid-1', {
                                'row-gap-md-12': !offset_media,
                                'row-gap-20 row-gap-md-22 mt-8 mt-md-0':
                                    offset_media,
                            })}
                        >
                            {features.map((feature, index) => {
                                return (
                                    <Feature
                                        key={index}
                                        center_items={center_items}
                                        elevation={elevation}
                                        offset_media={offset_media}
                                        variant={1}
                                        {...feature}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className="col-start-md-7 col-end-md-13 order-1 order-md-2">
                        {media && (
                            <Media
                                media={media}
                                sizes={getSizes({
                                    desktop: 28,
                                    tablet: 47,
                                })}
                            />
                        )}
                    </div>
                </div>

                {footnotes?.value && <Footnotes footnotes={footnotes} />}
            </div>
        </section>
    );
};
