import { Tout1a } from '@/blocks/tout/variants/tout-1a';
import { Tout1b } from '@/blocks/tout/variants/tout-1b';
import { Tout1c } from '@/blocks/tout/variants/tout-1c';

import { Tout2a } from '@/blocks/tout/variants/tout-2a';
import { Tout2b } from '@/blocks/tout/variants/tout-2b';
import { Tout2c } from '@/blocks/tout/variants/tout-2c';

import { Tout3a } from '@/blocks/tout/variants/tout-3a';
import { Tout3b } from '@/blocks/tout/variants/tout-3b';
import { Tout3c } from '@/blocks/tout/variants/tout-3c';
import { Tout3d } from '@/blocks/tout/variants/tout-3d';
import { Tout3e } from '@/blocks/tout/variants/tout-3e';
import { Tout3f } from '@/blocks/tout/variants/tout-3f';
import { Tout3g } from '@/blocks/tout/variants/tout-3g';

import { Tout4a } from '@/blocks/tout/variants/tout-4a';
import { Tout4b } from '@/blocks/tout/variants/tout-4b';

import { Tout5a } from '@/blocks/tout/variants/tout-5a';

import { Tout6a } from '@/blocks/tout/variants/tout-6a';

const prefix = 'tout';

const variants = {
    [`${prefix}_1a`]: Tout1a,
    [`${prefix}_1b`]: Tout1b,
    [`${prefix}_1c`]: Tout1c,
    [`${prefix}_2a`]: Tout2a,
    [`${prefix}_2b`]: Tout2b,
    [`${prefix}_2c`]: Tout2c,
    [`${prefix}_3a`]: Tout3a,
    [`${prefix}_3b`]: Tout3b,
    [`${prefix}_3c`]: Tout3c,
    [`${prefix}_3d`]: Tout3d,
    [`${prefix}_3e`]: Tout3e,
    [`${prefix}_3f`]: Tout3f,
    [`${prefix}_3g`]: Tout3g,
    [`${prefix}_4a`]: Tout4a,
    [`${prefix}_4b`]: Tout4b,
    [`${prefix}_5a`]: Tout5a,
    [`${prefix}_6a`]: Tout6a,
};

export const Tout = ({ ...block }) => {
    const Component = variants[block.variant];
    return Component ? <Component {...block} /> : null;
};
