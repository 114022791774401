import { isEven } from '@/lib/utils/is-even';
import { Media } from '@/components/media';
import { Footnotes } from '@/components/footnotes';
import { Feature } from '../elements/feature';
import classNames from 'classnames';
import { IntroButton } from '@/components/intro-button';
import { getSizes } from '@/lib/utils/get-sizes';

export const Features3a = ({
    settings,
    center_items,
    introduction,
    button,
    media,
    features,
    footnotes,
    attr,
}) => {
    return (
        <section
            className={classNames('section', {
                ...settings,
            })}
            {...attr}
        >
            <div className="container">
                <IntroButton introduction={introduction} button={button} />
                {media && (
                    <div className={'features__media d-md-none'}>
                        <Media
                            media={media}
                            sizes={getSizes({
                                desktop: 58,
                                tablet: 97,
                            })}
                        />
                    </div>
                )}
                <div className={'grid-md-12 row-gap-0 row-gap-md-7'}>
                    <div
                        className={
                            'col-start-md-1 col-end-md-4 row-start-md-1 z-1 grid-1 row-gap-md-12'
                        }
                    >
                        {features.map((feature, index) => {
                            if (isEven(index)) {
                                return (
                                    <Feature
                                        key={index}
                                        center_items={center_items}
                                        variant={1}
                                        {...feature}
                                    />
                                );
                            }
                        })}
                    </div>
                    <div
                        className={
                            'col-start-md-10 col-end-md-13 row-start-md-1 z-1 grid-1 row-gap-md-12'
                        }
                    >
                        {features.map((feature, index) => {
                            if (!isEven(index)) {
                                return (
                                    <Feature
                                        key={index}
                                        center_items={center_items}
                                        variant={1}
                                        {...feature}
                                    />
                                );
                            }
                        })}
                    </div>
                    <div className="col-start-md-1 col-end-md-13 row-start-1">
                        <Media
                            wrapperClassName={'full-size'}
                            fill
                            objectPosition="top"
                            media={media}
                            disableCaption
                            sizes={getSizes({
                                desktop: 58,
                                tablet: 97,
                            })}
                        />
                    </div>
                </div>
                {footnotes?.value && (
                    <Footnotes className="mt-14" footnotes={footnotes} />
                )}
            </div>
        </section>
    );
};
