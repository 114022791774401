import { ContentBlock } from './content-block';

export const NormalContentZone = ({ blocks }) => {
    return (
        <div className="content">
            {blocks?.map((block, index) => {
                return <ContentBlock key={index} block={block} />;
            })}
        </div>
    );
};
