'use client';

import dynamic from 'next/dynamic';

import { Icon } from '@/components/icon';
import classNames from 'classnames';
import { useState } from 'react';
import { useExpand } from '@/lib/hooks/use-expand';

const MotionDiv = dynamic(() =>
    import('motion/react').then((module) => module.motion.div)
);
const AnimatePresence = dynamic(() =>
    import('motion/react').then((module) => module.AnimatePresence)
);

import parse from 'html-react-parser';

export const Item = ({ item, count, numbered, children }) => {
    const [open, setOpen] = useState(false);
    const { expand } = useExpand();
    return (
        <div
            className={classNames('accordion__item')}
            data-kontent-item-id={item.id}
            data-kontent-element-codename={item.codename}
        >
            {numbered && (
                <div className="accordion__count">
                    {count < 10 ? '0' : ''}
                    {count}
                </div>
            )}
            <button
                className="accordion__trigger"
                onClick={() => {
                    setOpen(!open);
                }}
            >
                {item.eyebrow && (
                    <span className="accordion__eyebrow">
                        {parse(item.eyebrow)}
                    </span>
                )}
                {item.title}
            </button>

            <AnimatePresence>
                {(open || expand) && (
                    <MotionDiv
                        initial={{ height: 0 }}
                        animate={{ height: 'auto' }}
                        exit={{ height: 0 }}
                        transition={{ duration: 0.2 }}
                        className="accordion__content-wrapper"
                    >
                        {children}
                    </MotionDiv>
                )}
            </AnimatePresence>
            <div
                onClick={() => {
                    setOpen(!open);
                }}
            >
                <Icon
                    icon={'fa-regular fa-plus'}
                    wrapperIconClassName={classNames('accordion__icon', {
                        'accordion__icon--close': open,
                        'accordion__icon--eyebrow': item.eyebrow,
                    })}
                />
            </div>
        </div>
    );
};
