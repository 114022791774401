import { Introduction } from '@/components/introduction';
import { useForm } from '@/lib/hooks/use-form';
import { Footnotes } from '@/components/footnotes';
import { useRef } from 'react';
import { RichText } from '@/components/rich-text';
import classNames from 'classnames';
import { Results } from '../elements/results';
import { MarketoScript } from '../elements/marketo-script';
import { Form } from '../elements/form';

export const Form2c = ({
    settings,
    introduction,
    form,
    form_element,
    footnotes,
    thank_you_message,
    attr,
}) => {
    const ref = useRef();
    const { success, error, isPending, results, handleSubmit } = useForm({
        form,
        ref,
        form_element,
    });
    return (
        <>
            <MarketoScript />
            <section
                className={classNames('section form__wrapper', {
                    ...settings,
                })}
                {...attr}
                ref={ref}
                data-form={form}
            >
                <div className="container">
                    <Introduction {...introduction} />
                </div>
                {!success && (
                    <>
                        <Form
                            form={form_element}
                            isPending={isPending}
                            submit={handleSubmit}
                        />
                        {footnotes && (
                            <Footnotes
                                className="mt-10"
                                footnotes={footnotes}
                            />
                        )}
                        {error && (
                            <RichText
                                className="form__error"
                                data={{ value: error }}
                            />
                        )}
                    </>
                )}
                {success && (
                    <Results
                        success={success}
                        form_element={form_element}
                        thank_you_message={thank_you_message}
                        results={results}
                    />
                )}
            </section>
        </>
    );
};
