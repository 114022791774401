import { Introduction } from '@/components/introduction';
import { Footnotes } from '@/components/footnotes';
import { Tile } from '@/components/tile';
import classNames from 'classnames';

export const Resources3a = ({
    settings,
    introduction,
    footnotes,
    resources,
    attr,
}) => {
    return (
        <section
            className={classNames('section', {
                ...settings,
            })}
            {...attr}
        >
            <div className="container">
                <Introduction {...introduction} />
                <div className={'grid-lg-3 grid-md-2'}>
                    {resources.map((resource, index) => {
                        return <Tile key={index} variant={1} {...resource} />;
                    })}
                </div>
                {footnotes?.value && (
                    <Footnotes className="mt-14" footnotes={footnotes} />
                )}
            </div>
        </section>
    );
};
