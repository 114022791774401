import { Button } from '@/components/button';
import { Introduction } from '@/components/introduction';
import { Media } from '@/components/media';
import classNames from 'classnames';
import { AuthButton } from '@/components/auth-button';

export const Tout2b = ({
    introduction,
    settings,
    links,
    icon,
    attr,
    center_content_vertically,
}) => {
    return (
        <section
            className={classNames('section section--tight', {
                [`bg-${settings.theme}`]: settings.theme,
            })}
            {...attr}
        >
            <div className="container">
                <div
                    className={classNames('grid-md-12', {
                        'tout--center-vertically': center_content_vertically,
                    })}
                >
                    <div className="col-md-7">
                        {icon && (
                            <Media
                                wrapperClassName={'mb-4'}
                                className="tout__icon"
                                media={icon}
                            />
                        )}
                        <Introduction {...introduction} size={'sm'} />
                    </div>
                    {links && (
                        <div className="col-start-md-9 col-end-md-13 d-flex justify-center flex-column">
                            {links?.map((link, index) => {
                                return link.trigger === 'login_azure_ad' ? (
                                    <AuthButton
                                        key={index}
                                        {...link}
                                        btn_size={link.btn_size || 'md'}
                                        className={classNames({
                                            'mt-4': index === 1,
                                        })}
                                    >
                                        {link.cta_text}
                                    </AuthButton>
                                ) : (
                                    <Button
                                        key={index}
                                        {...link}
                                        btn_size={link.btn_size || 'md'}
                                        className={classNames({
                                            'mt-4': index === 1,
                                        })}
                                    >
                                        {link.cta_text}
                                    </Button>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};
