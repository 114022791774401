/**
 * Converts arrays to into strings and seperates the values by ";". This is marketos preferred way of storing array data
 */

export const convertArrayValues = (values) => {
    const updatedValues = {};
    for (const property in values) {
        if (Array.isArray(values[property])) {
            updatedValues[property] = values[property].join(';');
        } else {
            updatedValues[property] = values[property];
        }
    }
    return updatedValues;
};
