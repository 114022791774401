import dynamic from 'next/dynamic';
import classNames from 'classnames';
import { Suspense, useEffect } from 'react';
import { Icon } from '@/components/icon';
import {
    getRequiredConditions,
    getVisibilityConditions,
} from '@/lib/utils/get-form-element-fields';
const Clearable = dynamic(() =>
    import('./clearable').then((module) => module.Clearable)
);
import { useSearchParams } from 'next/navigation';

export const Text = ({ ...props }) => {
    return (
        <Suspense>
            <SuspendedText {...props} />
        </Suspense>
    );
};

const SuspendedText = ({
    register,
    label,
    name,
    visibility_conditions,
    required_conditions,
    default_value,
    validation,
    type,
    width,
    data,
    errors,
    icon,
    search,
    placeholder,
    setValue,
    disabled,
    reset,
    helper,
    clearable,
    id,
    codename,
}) => {
    const searchParams = useSearchParams();
    const query = {};
    searchParams.forEach((value, key) => {
        query[key] = value;
    });
    validation = {
        required: getRequiredConditions({
            data,
            required_conditions,
            required: validation.required,
        }),
        pattern: {
            value: validation?.pattern
                ? new RegExp(validation.pattern.value)
                : null,
            message: validation?.pattern ? validation?.pattern?.message : null,
        },
    };

    useEffect(() => {
        if (!default_value) return;
        setValue(name, default_value);
    }, [default_value]);

    useEffect(() => {
        if (data[reset]) {
            setValue(name, '');
        }
    }, [data[reset]]);

    useEffect(() => {
        // Automatically populates the field if its present in the url params
        if (query[name]) {
            setValue(name, query[name]);
        }
    }, [query[name]]);

    return (
        getVisibilityConditions({ data, visibility_conditions }) && (
            <div
                className={classNames('form__field', {
                    'form__field--full': width === 'full',
                    'form__field--third': width === 'third',
                    'form__field--half-new-line': width === 'half_new_line',
                })}
                data-kontent-item-id={id}
                data-kontent-element-codename={codename}
            >
                <label className="form__label">
                    {label}
                    <div className="form__validation">
                        <input
                            className={classNames('form__input', {
                                'form__input--icon': icon,
                                'form__input--search': search,
                                'form__input--error': errors[name],
                            })}
                            type={type}
                            placeholder={placeholder}
                            disabled={disabled}
                            {...register(name, validation)}
                        />
                        {icon && <Icon icon={icon} className="form__icon" />}
                        {search && (
                            <Icon
                                icon={'fa-solid fa-magnifying-glass'}
                                className="form__search"
                            />
                        )}
                        {clearable && !!data[name]?.length && (
                            <Clearable
                                data={data}
                                name={name}
                                setValue={setValue}
                            />
                        )}
                        {errors[name] && (
                            <div className="form__helper">
                                {errors[name]?.message}
                            </div>
                        )}
                        {helper && !errors[name] && (
                            <div className="form__helper">{helper}</div>
                        )}
                    </div>
                </label>
            </div>
        )
    );
};
