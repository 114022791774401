'use client';

import { Icon } from '@/components/icon';
import { useParams, useSearchParams } from 'next/navigation';
import { signIn } from 'next-auth/react';
import classNames from 'classnames';
import { Suspense } from 'react';

export const AuthButton = ({ ...props }) => {
    return (
        <Suspense>
            <SuspendedAuthButton {...props} />
        </Suspense>
    );
};

const SuspendedAuthButton = ({
    id,
    codename,
    btn_style,
    btn_size,
    icon_position,
    disabled,
    children,
    block,
    className,
    type,
    icon,
}) => {
    const params = useParams();
    const slug = params.slug;
    const normalizedSlug = Array.isArray(slug) ? slug : slug ? [slug] : [];
    const path = normalizedSlug.length ? `/${normalizedSlug.join('/')}` : '/';
    const paramlocale = params.locale;
    const searchParams = useSearchParams();

    const callbackUrl =
        searchParams.get('callbackUrl') ||
        `/${paramlocale === 'default' ? '' : paramlocale}${path}`;

    function handleClick() {
        signIn(`azure-${paramlocale == 'au' ? 'ad' : paramlocale}`, {
            callbackUrl,
        });
    }

    return (
        <button
            className={classNames('btn', {
                [`${className}`]: className,
                'btn-primary': btn_style === null || btn_style === undefined,
                [`btn-${btn_style}`]: btn_style,
                [`btn-${btn_size}`]: btn_size && btn_size !== 'md',
                'btn-block': block,
                'btn--disabled': disabled,
            })}
            disabled={disabled}
            type={type}
            onClick={handleClick}
            data-kontent-item-id={id}
            data-kontent-element-codename={codename}
        >
            {icon_position === 'left' && icon && (
                <Icon
                    icon={icon}
                    className={classNames({ 'mr-2': children })}
                />
            )}
            {children}
            {icon_position === 'right' && icon && (
                <Icon
                    icon={icon}
                    className={classNames({ 'ml-2': children })}
                />
            )}
        </button>
    );
};
