import { Introduction } from '@/components/introduction';
import { Button } from '@/components/button';
import classNames from 'classnames';

export const IntroButton = ({ introduction, button }) => {
    return (
        <>
            <Introduction
                className={classNames({ [`mb-6`]: button?.cta_text })}
                {...introduction}
            />
            {button?.cta_text && (
                <Button className={'mb-14'} {...button}>
                    {button.cta_text}
                </Button>
            )}
        </>
    );
};
